@font-face {
    font-family: "SBAggrolL";
    font-style: normal;
    font-weight: 400;
    src: url(../public/fonts/SBAggrolL.ttf) format('truetype')
}
@font-face {
    font-family: "SBAggrolL";
    font-style: normal;
    font-weight: 500;
    src: url(../public/fonts/SBAggrolM.ttf) format('truetype')
}
@font-face {
    font-family: "SBAggrolL";
    font-style: normal;
    font-weight: 600;
    src: url(../public/fonts/SBAggrolB.ttf) format('truetype')
}
@font-face {
    font-family: "NotoSansKr";
    font-style: normal;
    font-weight: 400;
    src: url(../public/fonts/NotoSansKR-Regular.woff) format('woff')
}
@font-face {
    font-family: "NotoSansKr";
    font-style: normal;
    font-weight: 500;
    src: url(../public/fonts/NotoSansKR-Medium.woff) format('woff')
}
@font-face {
    font-family: "NotoSansKr";
    font-style: normal;
    font-weight: 600;
    src: url(../public/fonts/NotoSansKR-Bold.woff) format('woff')
}
@font-face {
    font-family: "NotoSansKr";
    font-style: normal;
    font-weight: 700;
    src: url(../public/fonts/NotoSansKR-Black.woff) format('woff')
}
@font-face {
    font-family: "GongGothic";
    font-style: normal;
    font-weight: 400;
    src: url(../public/fonts/GongGothicLight.ttf) format('truetype')
}
@font-face {
    font-family: "GongGothic";
    font-style: normal;
    font-weight: 500;
    src: url(../public/fonts/GongGothicMedium.ttf) format('truetype')
}
@font-face {
    font-family: "GongGothic";
    font-style: normal;
    font-weight: 600;
    src: url(../public/fonts/GongGothicBold.ttf) format('truetype')
}
@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    src: url(../public/fonts/Pretendard-Light.woff) format('woff')
}
@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    src: url(../public/fonts/Pretendard-Regular.woff) format('woff')
}
@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    src: url(../public/fonts/Pretendard-Medium.woff) format('woff')
}
@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    src: url(../public/fonts/Pretendard-Bold.woff) format('woff')
}
@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    src: url(../public/fonts/Pretendard-ExtraBold.woff) format('woff')
}
:lang(ko),html,body {
    font-family:"NotoSansKr",'Apple SD Gothic Neo',Sans-serif;
}